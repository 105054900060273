import React, { useState, useEffect } from "react";
import ReadOnlyRow from "./ReadOnlyRow";
import InfiniteScroll from "react-infinite-scroll-component";
import classes from "./List.module.css";
import arrowUp from "../../assets/sorterArrowUp.png";
import arrowDown from "../../assets/sorterArrowDown.png";

function List({ estates, setLoadedEstates, setIsLoading, publicData }) {
  const [isEditing, setIsEditing] = useState(false);
  const [editForm, setEditForm] = useState({
    id: "",
    name: "",
    address: "",
    city: "",
    constructed: "",
  });
  function changeEditState(estate) {
    if (estate.id === editForm.id) {
      setIsEditing((isEditing) => !isEditing);
    } else if (isEditing === false) {
      setIsEditing((isEditing) => !isEditing);
    }
  }

  function captureEdit(clickedEstate) {
    let filtered = estates.filter((estate) => estate.id === clickedEstate.id);
    setEditForm(filtered[0]);
  }

  const [searchTerm, setSearchTerm] = useState("");
  const [showOwnProperties, setShowOwnProperties] = useState(false);
  const [count, setCount] = useState({
    prev: 0,
    next: 200,
  });
  const [hasMore, setHasMore] = useState(true);
  const [current, setCurrent] = useState(estates.slice(count.prev, count.next));
  const [searchCurrent, setSearchCurrent] = useState(estates);

  //search lenght
  const [searchEstates, setSearchEstates] = useState([]);
  var searchEstates2 = [];
  useEffect(() => {
    const timeout = setTimeout(() => {
      setSearch();
    }, 500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);
  function setSearch() {
    setSearchEstates(searchEstates2);
  }
  //

  const getMoreData = () => {
    if (current.length === estates.length) {
      setHasMore(false);
      return;
    }
    setTimeout(() => {
      setCurrent(
        current.concat(estates.slice(count.prev + 200, count.next + 200))
      );
    }, 2000);
    setCount((prevState) => ({
      prev: prevState.prev + 200,
      next: prevState.next + 200,
    }));
  };

  const [nameOrder, setNameOrder] = useState(false);
  const [addressOrder, setAddressOrder] = useState(false);
  const [postNumberOrder, setPostNumberOrder] = useState(false);
  const [cityOrder, setCityOrder] = useState(false);
  const [constructedOrder, setConstructedOrder] = useState(false);
  const [maintenanceDebtOrder, setMaintenanceDebtOrder] = useState(false);
  const [residentialAreaOrder, setResidentialAreaOrder] = useState(false);
  const [creatorOrder, setCreatorOrder] = useState(false);
  const [createdOrder, setCreatedOrder] = useState(false);
  const [editedOrder, setEditedOrder] = useState(false);

  function submitHandler(i) {
    const enteredValue = i;

    if (enteredValue === "taloyhtiönnimi" && nameOrder === false) {
      const nameEstates = [...estates].sort((a, b) => {
        let na = a.name.toLowerCase(),
          nb = b.name.toLowerCase();

        if (na < nb) {
          return -1;
        }
        if (na > nb) {
          return 1;
        }
        return 0;
      });
      if (current !== false) {
        setCurrent(nameEstates);
      } else {
        setSearchCurrent(nameEstates);
      }
      setNameOrder(true);
    } else if (enteredValue === "taloyhtiönnimi" && nameOrder === true) {
      const nameEstates = [...estates].sort((a, b) => {
        let na = a.name.toLowerCase(),
          nb = b.name.toLowerCase();

        if (na < nb) {
          return -1;
        }
        if (na > nb) {
          return 1;
        }
        return 0;
      });
      if (current !== false) {
        setCurrent(nameEstates.reverse());
      } else {
        setSearchCurrent(nameEstates.reverse());
      }
      setNameOrder(false);
    } else if (enteredValue === "osoite" && addressOrder === false) {
      const addressEstates = [...estates].sort((a, b) => {
        let aa = a.address.toLowerCase(),
          ab = b.address.toLowerCase();

        if (aa < ab) {
          return -1;
        }
        if (aa > ab) {
          return 1;
        }
        return 0;
      });
      if (current !== false) {
        setCurrent(addressEstates);
      } else {
        setSearchCurrent(addressEstates);
      }
      setAddressOrder(true);
    } else if (enteredValue === "osoite" && addressOrder === true) {
      const addressEstates = [...estates].sort((a, b) => {
        let aa = a.address.toLowerCase(),
          ab = b.address.toLowerCase();

        if (aa < ab) {
          return -1;
        }
        if (aa > ab) {
          return 1;
        }
        return 0;
      });
      if (current !== false) {
        setCurrent(addressEstates.reverse());
      } else {
        setSearchCurrent(addressEstates.reverse());
      }
      setAddressOrder(false);
    } else if (enteredValue === "postinumero" && postNumberOrder === false) {
      const postEstates = [...estates].sort(
        (a, b) => a.postNumber - b.postNumber
      );
      if (current !== false) {
        setCurrent(postEstates);
      } else {
        setSearchCurrent(postEstates);
      }
      setPostNumberOrder(true);
    } else if (enteredValue === "postinumero" && postNumberOrder === true) {
      const postEstates = [...estates].sort(
        (a, b) => a.postNumber - b.postNumber
      );
      if (current !== false) {
        setCurrent(postEstates.reverse());
      } else {
        setSearchCurrent(postEstates.reverse());
      }
      setPostNumberOrder(false);
    } else if (enteredValue === "kaupunki" && cityOrder === false) {
      const cityEstates = [...estates].sort((a, b) => {
        let ca = a.city.toLowerCase(),
          cb = b.city.toLowerCase();

        if (ca < cb) {
          return -1;
        }
        if (ca > cb) {
          return 1;
        }
        return 0;
      });
      if (current !== false) {
        setCurrent(cityEstates);
      } else {
        setSearchCurrent(cityEstates);
      }
      setCityOrder(true);
    } else if (enteredValue === "kaupunki" && cityOrder === true) {
      const cityEstates = [...estates].sort((a, b) => {
        let ca = a.city.toLowerCase(),
          cb = b.city.toLowerCase();

        if (ca < cb) {
          return -1;
        }
        if (ca > cb) {
          return 1;
        }
        return 0;
      });
      if (current !== false) {
        setCurrent(cityEstates.reverse());
      } else {
        setSearchCurrent(cityEstates.reverse());
      }
      setCityOrder(false);
    } else if (enteredValue === "rakennusvuosi" && constructedOrder === false) {
      const constructedEstates = [...estates].sort(
        (a, b) => a.constructed - b.constructed
      );
      if (current !== false) {
        setCurrent(constructedEstates);
      } else {
        setSearchCurrent(constructedEstates);
      }
      setConstructedOrder(true);
    } else if (enteredValue === "rakennusvuosi" && constructedOrder === true) {
      const constructedEstates = [...estates].sort(
        (a, b) => a.constructed - b.constructed
      );
      if (current !== false) {
        setCurrent(constructedEstates.reverse());
      } else {
        setSearchCurrent(constructedEstates.reverse());
      }
      setConstructedOrder(false);
    } else if (
      enteredValue === "korjausvelka" &&
      maintenanceDebtOrder === false
    ) {
      const totalEstates = [...estates].sort((a, b) => {
        const a_oldTotal_maintenanceData = a.total_maintenanceDebtPerSqM + "";
        const b_oldTotal_maintenanceData = b.total_maintenanceDebtPerSqM + "";
        let a_newTotal_maintenanceData = a_oldTotal_maintenanceData
          .replace("[", "")
          .replace("]", "");
        let b_newTotal_maintenanceData = b_oldTotal_maintenanceData
          .replace("[", "")
          .replace("]", "");
        a_newTotal_maintenanceData = a_newTotal_maintenanceData.split(",");
        a_newTotal_maintenanceData = a_newTotal_maintenanceData.map(Number);
        b_newTotal_maintenanceData = b_newTotal_maintenanceData.split(",");
        b_newTotal_maintenanceData = b_newTotal_maintenanceData.map(Number);
        return a_newTotal_maintenanceData[0] - b_newTotal_maintenanceData[0];
      });
      if (current !== false) {
        setCurrent(totalEstates);
      } else {
        setSearchCurrent(totalEstates);
      }
      setMaintenanceDebtOrder(true);
    } else if (
      enteredValue === "korjausvelka" &&
      maintenanceDebtOrder === true
    ) {
      const totalEstates = [...estates].sort((a, b) => {
        const a_oldTotal_maintenanceData = a.total_maintenanceDebtPerSqM + "";
        const b_oldTotal_maintenanceData = b.total_maintenanceDebtPerSqM + "";
        let a_newTotal_maintenanceData = a_oldTotal_maintenanceData
          .replace("[", "")
          .replace("]", "");
        let b_newTotal_maintenanceData = b_oldTotal_maintenanceData
          .replace("[", "")
          .replace("]", "");
        a_newTotal_maintenanceData = a_newTotal_maintenanceData.split(",");
        a_newTotal_maintenanceData = a_newTotal_maintenanceData.map(Number);
        b_newTotal_maintenanceData = b_newTotal_maintenanceData.split(",");
        b_newTotal_maintenanceData = b_newTotal_maintenanceData.map(Number);
        return a_newTotal_maintenanceData[0] - b_newTotal_maintenanceData[0];
      });
      if (current !== false) {
        setCurrent(totalEstates.reverse());
      } else {
        setSearchCurrent(totalEstates.reverse());
      }
      setMaintenanceDebtOrder(false);
    } else if (enteredValue === "pinta-ala" && residentialAreaOrder === false) {
      const areaEstates = [...estates].sort(
        (a, b) => a.residentialArea - b.residentialArea
      );
      if (current !== false) {
        setCurrent(areaEstates);
      } else {
        setSearchCurrent(areaEstates);
      }
      setResidentialAreaOrder(true);
    } else if (enteredValue === "pinta-ala" && residentialAreaOrder === true) {
      const areaEstates = [...estates].sort(
        (a, b) => a.residentialArea - b.residentialArea
      );
      if (current !== false) {
        setCurrent(areaEstates.reverse());
      } else {
        setSearchCurrent(areaEstates.reverse());
      }
      setResidentialAreaOrder(false);
    } else if (enteredValue === "laatija" && creatorOrder === false) {
      const creatorEstates = [...estates].sort((a, b) => {
        let ca = a.user.toLowerCase(),
          cb = b.user.toLowerCase();

        if (ca === "") {
          return 1;
        } else if (cb === "") {
          return -1;
        } else {
          return ca < cb ? -1 : ca > cb ? 1 : 0;
        }
      });
      if (current !== false) {
        setCurrent(creatorEstates);
      } else {
        setSearchCurrent(creatorEstates);
      }
      setCreatorOrder(true);
    } else if (enteredValue === "laatija" && creatorOrder === true) {
      const creatorEstates = [...estates].sort((a, b) => {
        let ca = a.user.toLowerCase(),
          cb = b.user.toLowerCase();

        if (ca === "") {
          return -1;
        } else if (cb === "") {
          return 1;
        } else {
          return ca < cb ? -1 : ca > cb ? 1 : 0;
        }
      });
      if (current !== false) {
        setCurrent(creatorEstates.reverse());
      } else {
        setSearchCurrent(creatorEstates.reverse());
      }
      setCreatorOrder(false);
    } else if (enteredValue === "luotu" && createdOrder === false) {
      const createdEstates = [...estates].sort((a, b) => a.id - b.id);
      if (current !== false) {
        setCurrent(createdEstates);
      } else {
        setSearchCurrent(createdEstates);
      }
      setCreatedOrder(true);
    } else if (enteredValue === "luotu" && createdOrder === true) {
      const createdEstates = [...estates].sort((a, b) => a.id - b.id);
      if (current !== false) {
        setCurrent(createdEstates.reverse());
      } else {
        setSearchCurrent(createdEstates.reverse());
      }
      setCreatedOrder(false);
    } else if (enteredValue === "päivitetty" && editedOrder === false) {
      const editedEstates = [...estates].sort((a, b) => {
        const [dayA, monthA, yearA] = a.edited.split('.').map(Number);
        const [dayB, monthB, yearB] = b.edited.split('.').map(Number);
        return new Date(yearA, monthA - 1, dayA) - new Date(yearB, monthB - 1, dayB);
      });
      if (current !== false) {
        setCurrent(editedEstates);
      } else {
        setSearchCurrent(editedEstates);
      }
      setEditedOrder(true);
    } else if (enteredValue === "päivitetty" && editedOrder === true) {
      const editedEstates = [...estates].sort((a, b) => {
        const [dayA, monthA, yearA] = a.edited.split('.').map(Number);
        const [dayB, monthB, yearB] = b.edited.split('.').map(Number);
        return new Date(yearA, monthA - 1, dayA) - new Date(yearB, monthB - 1, dayB);
      });
      if (current !== false) {
        setCurrent(editedEstates.reverse());
      } else {
        setSearchCurrent(editedEstates.reverse());
      }
      setEditedOrder(false);
    }    
  }
  function orderArrow(variable) {
    /* if (searchTerm !== "") {
      return null
    } */
    if (variable === false) {
      return (
        <img
          src={arrowDown}
          alt="↓"
          style={{ width: "8px", cursor: "pointer" }}
        ></img>
      );
    } else {
      return (
        <img
          src={arrowUp}
          alt="↑"
          style={{ width: "8px", cursor: "pointer" }}
        ></img>
      );
    }
  }

  function isPrivilegeTrue() {
    if (publicData.privilege === 0) {
      return true;
    } else if (publicData.privilege === 1) {
      return true;
    } else {
      return false;
    }
  }

  function ownProperties(boolean) {
    setShowOwnProperties(boolean);
    if (boolean) {
      setSearchTerm(publicData.username);
    } else {
      setSearchTerm("");
    }
  }

  return (
    <div className={classes.center}>
      <InfiniteScroll
        dataLength={current.length}
        next={current && getMoreData}
        hasMore={hasMore}
        loader={
          current ? (
            <h4
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                marginBlock: "5%",
              }}
            >
              Ladataan uusia kiinteistöjä...
            </h4>
          ) : (
            <br />
          )
        }
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "0.5%",
            alignItems: "end",
          }}
        >
          <div style={{ display: "flex", gap: "20px" }}>
            <div>
              <form style={{ width: "50%" }}>
                <input
                  type="text"
                  placeholder="Etsi..."
                  onChange={(event) => {
                    setSearchTerm(event.target.value);
                    setShowOwnProperties(false);
                  }}
                />
              </form>
              <p style={{ fontSize: "14px", marginBottom: "0" }}>
                Haku toimii nimen, osoitteen tai postinumeron mukaan.
              </p>
            </div>
            <div style={{ display: "flex", alignItems: "end", gap: "5px" }}>
              <input
                type="checkbox"
                checked={showOwnProperties}
                style={{ width: "15px", height: "15px", marginBottom: "4px" }}
                onChange={(e) => {
                  ownProperties(e.target.checked);
                }}
              />
              <p style={{ marginBottom: "0", whiteSpace: "nowrap" }}>
                Omat kohteet
              </p>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <p style={{ margin: "0" }}>
              Hakutuloksia yhteensä: <b>{searchEstates.length}</b>
            </p>
            <div style={{ width: "20px" }}></div>
            <p style={{ margin: "0" }}>
              Kiinteistöjä yhteensä: <b>{estates.length}</b>
            </p>
          </div>
        </div>
        <table className={classes.local}>
          <thead>
            <tr>
              <th
                onClick={() => submitHandler("taloyhtiönnimi")}
                style={{ cursor: "pointer" }}
              >
                <div style={{ display: "grid" }}>
                  Taloyhtiön Nimi {orderArrow(nameOrder)}
                </div>
              </th>
              <th
                onClick={() => submitHandler("osoite")}
                style={{ cursor: "pointer" }}
              >
                <div style={{ display: "grid" }}>
                  Osoite {orderArrow(addressOrder)}
                </div>
              </th>
              <th
                onClick={() => submitHandler("postinumero")}
                style={{ cursor: "pointer" }}
              >
                <div style={{ display: "grid" }}>
                  Postinumero {orderArrow(postNumberOrder)}
                </div>
              </th>
              <th
                onClick={() => submitHandler("kaupunki")}
                style={{ cursor: "pointer" }}
              >
                <div style={{ display: "grid" }}>
                  Kaupunki {orderArrow(cityOrder)}
                </div>
              </th>
              <th>Kiinteistön tyyppi</th>
              <th
                onClick={() => submitHandler("rakennusvuosi")}
                style={{ cursor: "pointer" }}
              >
                <div style={{ display: "grid" }}>
                  Rakennusvuosi {orderArrow(constructedOrder)}
                </div>
              </th>
              <th
                onClick={() => submitHandler("korjausvelka")}
                style={{ cursor: "pointer" }}
              >
                <div style={{ display: "grid" }}>
                  <div>
                    Korjausvelka (€/m<sup>2</sup>)
                  </div>{" "}
                  {orderArrow(maintenanceDebtOrder)}
                </div>
              </th>
              <th
                onClick={() => submitHandler("pinta-ala")}
                style={{ cursor: "pointer" }}
              >
                <div style={{ display: "grid" }}>
                  <div>
                    Asuin pinta-ala (m<sup>2</sup>)
                  </div>{" "}
                  {orderArrow(residentialAreaOrder)}
                </div>
              </th>
              <th>
                <div style={{ display: "grid" }}>
                  <div>
                    Asunnon pinta-ala (m<sup>2</sup>)
                  </div>
                </div>
              </th>
              {isPrivilegeTrue() ? (
                <th onClick={() => submitHandler("laatija")}>
                  <div style={{ display: "grid" }}>
                    <div>Kiinteistön laatija</div> {orderArrow(creatorOrder)}
                  </div>
                </th>
              ) : null}
              {isPrivilegeTrue() ? (
                <th onClick={() => submitHandler("luotu")}>
                  <div style={{ display: "grid" }}>
                    <div>Luotu</div> {orderArrow(createdOrder)}
                  </div>
                </th>
              ) : null}
              {isPrivilegeTrue() ? (
                <th onClick={() => submitHandler("päivitetty")}>
                  <div style={{ display: "grid" }}>
                    <div>Päivitetty</div> {orderArrow(editedOrder)}
                  </div>
                </th>
              ) : null}
              <th>Hallitse</th>
            </tr>
          </thead>
          <tbody>
            {current &&
              current
                .filter((estate) => {
                  if (searchTerm !== "") {
                    setCurrent(false);
                  }
                  if (!isPrivilegeTrue() && estate.name.includes("epävarma")) {
                    return false;
                  }
                  return true;
                })
                .map((estate, index) => (
                  <ReadOnlyRow
                    key={index}
                    estate={estate}
                    captureEdit={captureEdit}
                    changeEditState={changeEditState}
                    setLoadedEstates={setLoadedEstates}
                    setIsLoading={setIsLoading}
                    loadedEstates={estates}
                    publicData={publicData}
                  />
                ))}
            {!current
              ? (searchEstates2 = searchCurrent
                  .filter((estate) => {
                    // Directly return false if there's no search term and set the current estates based on pagination
                    if (searchTerm === "") {
                      setCurrent(estates.slice(count.prev, count.next));
                      return false;
                    }

                    // Combine the conditions for matching search terms with a simpler approach
                    const searchTermLower = searchTerm.toLowerCase();
                    if (
                      estate.name.toLowerCase().includes(searchTermLower) ||
                      estate.city.toLowerCase().includes(searchTermLower) ||
                      estate.postNumber
                        .toLowerCase()
                        .includes(searchTermLower) ||
                      estate.address.toLowerCase().includes(searchTermLower) ||
                      estate.user.toLowerCase().includes(searchTermLower)
                    ) {
                      if (
                        !isPrivilegeTrue() &&
                        estate.name.includes("epävarma")
                      ) {
                        return false;
                      }
                      return true;
                    }
                    return false;
                  })
                  .map((estate, index) => (
                    <ReadOnlyRow
                      key={index}
                      estate={estate}
                      captureEdit={captureEdit}
                      changeEditState={changeEditState}
                      setLoadedEstates={setLoadedEstates}
                      setIsLoading={setIsLoading}
                      loadedEstates={estates}
                      publicData={publicData}
                    />
                  )))
              : null}
          </tbody>
        </table>
      </InfiniteScroll>
    </div>
  );
}

export default List;
