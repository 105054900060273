//Idea of this page is to construct a report for our customers
import DebtChart from "../components/chart/Chart";
import classes from "./Raport.module.css";
import "../components/estatelist/List.module.css";
import hox from "../assets/HOX.png";
import question from "../assets/question-mark.png";
import returnArrow from "../assets/returnArrow.png";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { url } from "./HomePage";
import PropertyType from "../components/propertytype/PropertyType";
/* import CompensationChart from "../components/chart/CompensationChart"; */
import CheckNaN from "../components/checknan/CheckNaN";
import CheckTotal from "../components/checknan/CheckTotal";
import { useNavigate } from "react-router-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import A4Raport from "./4sRaport/A4Raport";
import A4Raport2 from "./4sRaport/A4Raport2";
import SeparatedCompensationChart from "../components/chart/SeparatedCompensationChart";
import osa1 from "../assets/1-osa.pdf";
import A4RaportOnePage from "./A4Raport";

function Raport() {
  const params = useParams();
  const [Data, setData] = useState([]);
  const [publicData, setPublicData] = useState({});
  const [Defaults, setDefaults] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [out, setOut] = useState("");
  const [showButtons, setShowButtons] = useState(false);
  const id = params.id;
  const openInSamePage = (url) => {
    window.open(url, "_self", "noopener,noreferrer");
  };
  const navigate = useNavigate();

  //this useEffect formats some data, checks your rights to be on this page and downloads all the necessary data
  useEffect(() => {
    fetch(url + "me", {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status === 401) {
          console.log("token invalid");
          window.location.href = "/";
        }
        setIsLoading(false);
        return response.json();
      })
      .then((data) => setPublicData(data));

    fetch(url + "api/properties/" + params.id, {
      credentials: "include",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // new address
        let oldAddress = data.address + "";
        oldAddress = oldAddress.split(" / ");
        data.address = `[${oldAddress}]`
          .replaceAll("[", "")
          .replaceAll("]", "")
          .replaceAll(",", ", ");
        //
        setData(data);
      });

    fetch(url + "api/defaults/spans", {
      credentials: "include",
    })
      .then((response) => {
        return response.json();
      })

      .then((i) => {
        setDefaults(i);
      });
    const timer = setTimeout(() => {
      setShowButtons(true);
    }, 2000);
    return () => clearTimeout(timer);
  }, [params.id]);
  if (isLoading) {
    return null;
  }

  //this commented function was our way to update repair debt. It's here because it might be useful someday
  /* function updateKorjausvelka() {
    fetch(
      url + "api/properties/" +
      params.id +
      "/updatemaintenancedebt",
      {
        credentials: 'include',
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setData(data);
      });
    setTimeout(() => {
      window.location.reload(false);
    }, 500);
  } */

  //next three functions are for our buttons on report page
  async function downloadReport(format) {
    setLoadingScreen(true);
    document.getElementById("nappi").disabled = true;
    await fetch(url + "api/properties/" + id + "/download/" + format, {
      method: "GET",
      credentials: "include",
    })
      .then((res) => {
        if (!res.ok) {
          setOut("Lataus epäonnistui! Päivitä sivu!");
        } else {
          return res.blob();
        }
      })
      .then((data) => {
        if (out !== "") {
          return;
        } else {
          let blobUrl = window.URL.createObjectURL(data);
          var a = document.createElement("a");

          var date = new Date();

          a.download =
            "Korjausvelkalaskurin_raportti_" +
            Data.name.replace(/ /g, "_") +
            "_" +
            date.getFullYear() +
            "_" +
            (date.getMonth() + 1) +
            "_" +
            date.getDate() +
            "." +
            format;
          a.href = blobUrl;
          // Force the download of the blob with a certain filename
          document.body.appendChild(a);
          a.click();
          a.remove();
        }
        setTimeout(() => {
          document.getElementById("nappi").disabled = false;
        }, 1000);
        setLoadingScreen(false);
      });
  }

  async function downloadAd(format) {
    setLoadingScreen(true);
    document.getElementById("nappi2").disabled = true;
    await fetch(url + "api/properties/" + id + "/download/ad/" + format, {
      method: "GET",
      credentials: "include",
    })
      .then((res) => {
        if (!res.ok) {
          setOut("Mainoksen lataus epäonnistui! Päivitä sivu!");
        } else {
          return res.blob();
        }
      })
      .then((data) => {
        if (out !== "") {
          return;
        } else {
          let blobUrl = window.URL.createObjectURL(data);
          var a = document.createElement("a");

          var date = new Date();

          a.download =
            "Korjausvelkalaskuri_mainos_" +
            Data.name.replace(/ /g, "_") +
            "_" +
            date.getFullYear() +
            "_" +
            (date.getMonth() + 1) +
            "_" +
            date.getDate() +
            "." +
            format;
          a.href = blobUrl;
          // Force the download of the blob with a certain filename
          document.body.appendChild(a);
          a.click();
          a.remove();
        }
        setTimeout(() => {
          document.getElementById("nappi2").disabled = false;
        }, 1000);
        setLoadingScreen(false);
      });
  }

  async function deleteEstate() {
    if (
      publicData.organization === "testgroup" ||
      publicData.organization === "Kilpailija"
    ) {
      await fetch(url + "api/properties/" + id, {
        credentials: "include",
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setData(data);
        });
      if (Data.user === publicData.username) {
        fetch(url + "api/properties/" + id, {
          method: "DELETE",
          credentials: "include",
        }).then(() => {
          window.location.href = "/kotisivu";
        });
      } else {
        window.confirm(
          "Et voi poistaa tätä kiinteistöä, koska et ole luonut sitä"
        );
      }
    } else {
      fetch(url + "api/properties/" + id, {
        method: "DELETE",
        credentials: "include",
      }).then(() => {
        window.location.href = "/kotisivu";
      });
    }
  }

  function isPrivilegeTrue() {
    if (publicData.privilege === 0) {
      return true;
    } else if (publicData.privilege === 1) {
      return true;
    } else {
      return false;
    }
  }

  function downloadA4Report() {
    setLoadingScreen(true);
    const a4PageSize = { width: 210, height: 297 };
    const capture = document.querySelector(".report3");
    html2canvas(capture, {
      scale: 1.3, //can be changed to 2 if not working correctly
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg");
      const doc = new jsPDF("p", "mm", "a4");
      const aspectRatio = 2580 / 3508;
      const imgWidth = a4PageSize.width;
      const imgHeight = a4PageSize.width / aspectRatio;
      const tmpName = Data.name + "";
      doc.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight);
      doc.save(
        `Korjausvelkalaskurin_1s_raportti_${
          tmpName.replaceAll(" ", "_") +
          "_" +
          new Date().toLocaleDateString("fi-FI").replaceAll(".", "_")
        }.pdf`
      );
      setLoadingScreen(false);
    });
  }

  async function download4PageA4Report() {
    setLoadingScreen(true);
    const capture = document.querySelector(".report");
    const capture2 = document.querySelector(".report2");

    html2canvas(capture, { scale: 1.3 }).then(async (canvas) => {
      const imgData1 = canvas.toDataURL("image/jpeg");
      const doc = new jsPDF("p", "mm", "a4");

      const a4Width = 210; // A4 width in mm
      const a4Height = 297; // A4 height in mm

      const imgWidth = a4Width;
      const imgHeight = (canvas.height / canvas.width) * a4Width; // Maintain aspect ratio

      let x = 0;
      let y = 0;
      if (imgHeight > a4Height) {
        const scaleFactor = a4Height / imgHeight;
        const newWidth = imgWidth * scaleFactor;
        const newHeight = imgHeight * scaleFactor;
        x = (a4Width - newWidth) / 2; // Center horizontally
        y = (a4Height - newHeight) / 2; // Center vertically

        doc.addImage(imgData1, "JPEG", x, y, newWidth, newHeight);
      } else {
        doc.addImage(imgData1, "JPEG", 0, 0, imgWidth, imgHeight);
      }

      const tmpName = Data.name + "";

      // Capture the second report section
      const canvas2 = await html2canvas(capture2, { scale: 1.3 });
      const imgData2 = canvas2.toDataURL("image/jpeg");

      // Add the second captured image to the PDF
      doc.addPage();
      doc.addImage(imgData2, "JPEG", 0, 0, imgWidth, imgHeight);

      // Add footer text right under the last image in a smaller font
      doc.setFontSize(10);
      doc.text(
        `Tämä raportti on ladattu Korjausvelkalaskurista ${new Date().toLocaleDateString(
          "fi-FI"
        )}`,
        22,
        imgHeight + 5
      );

      // Convert jsPDF output to an ArrayBuffer
      const generatedPdfBytes = doc.output("arraybuffer");

      // Load pdf-lib dynamically
      const { PDFDocument } = window["pdf-lib"] || (await import("pdf-lib"));

      // Load the existing PDF from the assets folder
      const existingPdfUrl = osa1; // Make sure this path is correct!
      const existingPdfResponse = await fetch(existingPdfUrl);
      if (!existingPdfResponse.ok) {
        console.error(
          "Failed to load existing PDF:",
          existingPdfResponse.statusText
        );
        return;
      }
      const existingPdfBytes = await existingPdfResponse.arrayBuffer();

      // Create a new PDF document
      const pdfDoc = await PDFDocument.create();

      // Copy pages from the existing PDF
      const existingPdf = await PDFDocument.load(existingPdfBytes);
      const existingPages = await pdfDoc.copyPages(
        existingPdf,
        existingPdf.getPageIndices()
      );
      existingPages.forEach((page) => pdfDoc.addPage(page));

      // Copy pages from the generated PDF
      const generatedPdf = await PDFDocument.load(generatedPdfBytes);
      const generatedPages = await pdfDoc.copyPages(
        generatedPdf,
        generatedPdf.getPageIndices()
      );
      generatedPages.forEach((page) => pdfDoc.addPage(page));

      // Save merged PDF using jsPDF
      const mergedPdfBytes = await pdfDoc.save();
      const mergedBlob = new Blob([mergedPdfBytes], {
        type: "application/pdf",
      });

      // Create a download link
      const link = document.createElement("a");
      link.href = URL.createObjectURL(mergedBlob);
      link.download = `Korjausvelkalaskurin_4s_raportti_${
        tmpName.replaceAll(" ", "_") +
        "_" +
        new Date().toLocaleDateString("fi-FI").replaceAll(".", "_")
      }.pdf`;
      link.click();
      setLoadingScreen(false);
    });
  }

  function trafficLight(value1, value2) {
    /* console.log("value1: " + value1 + " " + "value2: " + value2) */
    let theValue = value2 < 0 ? value1 : Math.min(value1, value2);
    console.log(theValue);
    if (theValue <= 5) {
      console.log("returned: red");
      return 0;
    } else if (theValue <= 15) {
      console.log("returned: yellow");
      return 1;
    } else {
      console.log("returned: green");
      return 2;
    }
  }

  return (
    <div>
      <div className={classes.padding}>
        {showButtons && (
          <div>
            <img
              src={returnArrow}
              alt="takaisin"
              style={{ width: "40px", cursor: "pointer" }}
              onClick={() => window.history.back()}
            ></img>
            <button
              id="nappi"
              onClick={() =>
                /*  downloadReport("pdf") */ download4PageA4Report()
              }
            >
              Lataa raportti
            </button>
            <button onClick={() => downloadReport("docx")} hidden disabled>
              Lataa Microsoft Word muodossa
            </button>
            <button
              id="nappi2"
              onClick={() => /* downloadAd("jpg") */ downloadA4Report()}
            >
              Lataa yksisivuinen raportti
            </button>
            <button onClick={() => downloadAd("pdf")} hidden>
              Lataa mainos pdf muodossa
            </button>
            <button
              onClick={() =>
                window.confirm(`Haluatko poistaa kiinteistön: ${Data.name}?`) &&
                deleteEstate()
              }
            >
              Poista
            </button>
            <button
              onClick={() => {
                if (
                  publicData.privilege === 0 ||
                  publicData.privilege === 1 ||
                  Data.checked === false
                ) {
                  openInSamePage("/hallitse/" + id);
                } else {
                  window.alert(
                    "Kiinteistö on varmistettu. Sitä pääsee muokkaamaan vain adminit"
                  );
                }
              }}
              /* style={{ "margin-left": "0px" }} */
            >
              Muokkaa
            </button>
            <p style={{ color: "red" }}>{out}</p>
          </div>
        )}
        {loadingScreen ? (
          <p>Tiedostoa ladataan. Siinä voi kestää hetki.</p>
        ) : (
          <></>
        )}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <h1>{Data.name}</h1>
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <nobr style={{ display: "flex", flexDirection: "row" }}>
                <h5 style={{ paddingRight: "1%" }}>{Data.address},</h5>
                <h5 style={{ paddingRight: "1%" }}>{Data.postNumber}</h5>
                <h5 style={{ paddingRight: "1%" }}>{Data.city},</h5>
                <PropertyType propertyType={Data.propertyType} />
                <h5 style={{ paddingRight: "1%" }}>{Data.floors} krs,</h5>
                <h5 style={{ paddingRight: "1%" }}>
                  Rakennusvuosi: {Data.constructed}
                </h5>
              </nobr>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div style={{ display: "flex" }}>
                <h5>{isPrivilegeTrue() === true ? "Laatija:" : null}</h5>
                <div style={{ width: "1rem" }}></div>
                <h5>
                  {isPrivilegeTrue() === true && Data.pubD
                    ? Data.user +
                      " " +
                      Data.pubD
                        .toString()
                        .split("T")[0]
                        .split("-")
                        .reverse()
                        .join(".")
                    : null}
                </h5>
              </div>
              {isPrivilegeTrue() === true && Data.editor !== "" ? (
                <div style={{ display: "flex" }}>
                  <h5>Editoitu:</h5>
                  <div style={{ width: "1rem" }}></div>
                  <h5>
                    {Data.edited
                      ? Data.editor +
                        " " +
                        Data.edited
                          .toString()
                          .split("T")[0]
                          .split("-")
                          .reverse()
                          .join(".")
                      : null}
                  </h5>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <br />
        <div style={{ display: "grid", width: "100%", alignItems: "start" }}>
          <h4 style={{ margin: 0 }}>Yhteenveto talon tekniikan tilasta</h4>
          <div style={{ display: "flex", gap: "0.5%", alignItems: "center" }}>
            <img
              src={hox}
              alt="prop"
              style={{ height: "20px", paddingLeft: "0.5%", opacity: "0.8" }}
            />
            <p style={{ margin: "0" }}>
              Seuraavia arvoja käytettiin tehtyjen korjausvelka laskelmien
              pohjana. Laskelmat ovat riippuvaisia näiden tietojen
              oikeellisuudesta.
            </p>
            <br />
            <br />
          </div>
        </div>
        <table
          className={classes.local}
          style={{
            backgroundColor: "white",
            boxShadow: "1px 3px 13px rgba(0, 0, 0, 0.2)",
            borderRadius: "10px",
          }}
        >
          <thead>
            <tr>
              <th style={{ width: "20%" }}></th>
              <th style={{ width: "10%" }}>Remontoitava määrä</th>
              <th style={{ width: "10%" }}>
                Rakennettu / remontoitu viimeksi v
              </th>
              <th style={{ width: "10%" }}>Tyypillinen kestoikä v</th>
              <th style={{ width: "10%" }}>Arvioitu korjausvuosi v</th>
              <th style={{ width: "10%" }}>
                Korjauksen hinta € / m<sup>2</sup>
              </th>
              <th style={{ width: "10%" }}>
                Taloyhtiön korjausvelka € / m<sup>2</sup>
              </th>
              <th style={{ width: "10%" }}>
                {Data.apartmentArea} m<sup>2</sup> Asunnon korjausvelka €
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th className={classes.row}>
                Viemäriputket
                <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                  <span
                    style={
                      trafficLight(
                        Number(Data.sewer_actualPlanned) -
                          new Date(Date.now()).getFullYear(),
                        Data.sewer_renovated +
                          Defaults.Sewerspan -
                          new Date(Date.now()).getFullYear()
                      ) === 0
                        ? {
                            width: "19px",
                            height: "19px",
                            backgroundColor: "red",
                            borderRadius: "50%",
                            border: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }
                        : trafficLight(
                            Number(Data.sewer_actualPlanned) -
                              new Date(Date.now()).getFullYear(),
                            Data.sewer_renovated +
                              Defaults.Sewerspan -
                              new Date(Date.now()).getFullYear()
                          ) === 1
                        ? {
                            width: "19px",
                            height: "19px",
                            backgroundColor: "yellow",
                            borderRadius: "50%",
                            border: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }
                        : {
                            width: "19px",
                            height: "19px",
                            backgroundColor: "green",
                            borderRadius: "50%",
                            border: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }
                    }
                  />
                  <img
                    id="sewer-img"
                    src={question}
                    alt="prop"
                    onClick={() =>
                      navigate("/info", {
                        state: {
                          title: "Viemäriputket",
                          text: "Vesi- ja viemäriputkien vaihtaminen on tyypillisesti taloyhtiön suurin remontti, jolla on myös suurin haitta asumiselle. Ne vievät jätevedet pois asunnon lavuaareista, pesukoneista, WC istuimista ja lattiakaivoista. Vanhojen viemäreiden vaihtaminen uusiin kuuluu perinteiseen viemäriputkiremonttiin.\n\nVanhoissa taloissa nämä putket ovat tyypillisesti valettu seinien rappausten sisään ns. uppoasennuksina. Uudemmissa taloissa vesiputket on usein asennettu seinien päälle ja mahdollisen alaslasketun katon taakse ns. pinta-asennuksina. Viemäriputket ovat valurautaisia, uudemmat ovat yleensä muovisia. Kohtuullisessa kunnossa olevia viemäriputkia voidaan myös sukittaa tai pinnoittaa säilyttäen olemassa olevat putket ja asentamalla niiden putkien sisään uuden muovi- tai lasikuitupäällysteen.",
                        },
                      })
                    }
                    style={{
                      height: "20px",
                      paddingLeft: "0.5%",
                      opacity: "0.8",
                    }}
                  />
                </div>
              </th>
              <td>{Math.round(Data.sewer_area)} m²</td>
              <td>{Data.sewer_renovated}</td>
              <td>{Defaults.Sewerspan}</td>
              <td>{Data.sewer_actualPlanned}</td>
              <td>{Math.round(Data.sewer_rRPrice)}</td>
              <td>
                <CheckNaN
                  maintenance={Data.sewer_maintenance}
                  apartment={Data.apartmentArea}
                />
              </td>
              <td>{Data.sewer_maintenance}</td>
            </tr>
            <tr>
              <th className={classes.row}>
                Käyttövesiputket
                <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                  <span
                    style={
                      trafficLight(
                        Number(Data.plumbing_actualPlanned) -
                          new Date(Date.now()).getFullYear(),
                        Data.plumbing_renovated +
                          Defaults.Plumbingspan -
                          new Date(Date.now()).getFullYear()
                      ) === 0
                        ? {
                            width: "19px",
                            height: "19px",
                            backgroundColor: "red",
                            borderRadius: "50%",
                            border: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }
                        : trafficLight(
                            Number(Data.plumbing_actualPlanned) -
                              new Date(Date.now()).getFullYear(),
                            Data.plumbing_renovated +
                              Defaults.Plumbingspan -
                              new Date(Date.now()).getFullYear()
                          ) === 1
                        ? {
                            width: "19px",
                            height: "19px",
                            backgroundColor: "yellow",
                            borderRadius: "50%",
                            border: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }
                        : {
                            width: "19px",
                            height: "19px",
                            backgroundColor: "green",
                            borderRadius: "50%",
                            border: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }
                    }
                  />
                  <img
                    id="plumbing-img"
                    src={question}
                    alt="prop"
                    onClick={() =>
                      navigate("/info", {
                        state: {
                          title: "Käyttövesiputket",
                          text: "Käyttövesiputket eli vesiputket tuovat kylmän ja kuuman käyttöveden vesihanoihin, suihkuihin ja pesukoneille. Ne ovat tyypillisesti kuparia, galvanoitua terästä tai muovia kulloisenkin rakennusajan rakennustekniikasta riippuen. Käyttövesiputket vaihdetaan nykyään tyypillisesti uusiin eikä vanhoja yritetä korjata tai pinnoittaa. 2000-luvun alussa niitä myös pinnoitettiin, mutta tämä korjaustapa ei ole oikeastaan enää Suomessa käytössä. Pinta-asennuksina olevat tai kylpyhuoneen kulmaan koteloidut käyttövesiputket on helpompi vaihtaa kuin märkätilojen seinien sisään asennetut putket. Vesiputkien rikkoutuminen ja vuotaminen voi aiheuttaa ison vahingon veden mahdollisesti valuessa talon alempiin kerroksiin.",
                        },
                      })
                    }
                    style={{
                      height: "20px",
                      paddingLeft: "0.5%",
                      opacity: "0.8",
                    }}
                  />
                </div>
              </th>
              <td>{Math.round(Data.plumbing_area)} m²</td>
              <td>{Data.plumbing_renovated}</td>
              <td>{Defaults.Plumbingspan}</td>
              <td>{Data.plumbing_actualPlanned}</td>
              <td>{Math.round(Data.plumbing_rRPrice)}</td>
              <td>
                <CheckNaN
                  maintenance={Data.plumbing_maintenance}
                  apartment={Data.apartmentArea}
                />
              </td>
              <td>{Data.plumbing_maintenance}</td>
            </tr>
            <tr>
              <th className={classes.row}>
                Märkätilat
                <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                  <span
                    style={
                      trafficLight(
                        Number(Data.bathroom_actualPlanned) -
                          new Date(Date.now()).getFullYear(),
                        Data.bathroom_renovated +
                          Defaults.Bathroomspan -
                          new Date(Date.now()).getFullYear()
                      ) === 0
                        ? {
                            width: "19px",
                            height: "19px",
                            backgroundColor: "red",
                            borderRadius: "50%",
                            border: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }
                        : trafficLight(
                            Number(Data.bathroom_actualPlanned) -
                              new Date(Date.now()).getFullYear(),
                            Data.bathroom_renovated +
                              Defaults.Bathroomspan -
                              new Date(Date.now()).getFullYear()
                          ) === 1
                        ? {
                            width: "19px",
                            height: "19px",
                            backgroundColor: "yellow",
                            borderRadius: "50%",
                            border: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }
                        : {
                            width: "19px",
                            height: "19px",
                            backgroundColor: "green",
                            borderRadius: "50%",
                            border: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }
                    }
                  />
                  <img
                    id="bathroom-img"
                    src={question}
                    alt="prop"
                    onClick={() =>
                      navigate("/info", {
                        state: {
                          title: "Märkätilat",
                          text: "Asuntojen märkätiloihin kuuluvat kylpyhuoneet ja mahdollisesti WC tilat. Niiden uusiminen tehdään lähes aina vesiputkien ja varsinkin viemäriputkien uusimisen kanssa. Syynä ovat mm. 1999 alkaen voimassa olevat rakennusmääräykset, jotka velvoittavat kylpyhuoneen kaakelien alle ja WC tilan lattiaan säännösten mukaisen vesieristeen. Yleensä koko vesieriste joudutaan uusimaan, jos uppoasennuksena olevat käyttövesiputket tai lattiakaivo ja siitä lähtevä viemäri uusitaan.\n\nNäiden vesieristeiden elinikä on parhaimmillaankin 40 vuotta, mutta huonoimmillaan vain puolet tuosta. Tästäkin syystä näitä kph ja WC remontteja tehdään useammin kuin varsinaisten putkien uusimista.\n\nTaloyhtiön remonteissa taloyhtiö on vastuussa vesieristeen uusimisesta ja maksaa sen sekä vesieristeen takana olevat korjaukset. Tyypillisesti myös WC-istuimet, hanat ja niiden uusiminen kuuluu taloyhtiölle Kiinteistöliiton vastuunjakotaulukon mukaan. Vesieristeen päällä olevat kaakelit taloyhtiö voi uusia siihen tasoon saakka, missä rakennus oli sitä rakennettaessa tai edellisen taloyhtiön remontin jälkeen. Tämän ns. taloyhtiön perustason määrittely pitää tehdä ennen märkätilojen uusimisurakan alkua.\n\nMärkätilojen peilit, kaapit ja tasot kuuluvat asunnon omistavan osakkaan vastuulle ja maksettavaksi. Näistä poikkeavista märkätilojen korjausten kustannusten vastuujaoista pitää kertoa yhtiöjärjestyksessa, eli taloyhtiön omissa säännöissä tai taloyhtiön muissa dokumenteissa.",
                        },
                      })
                    }
                    style={{
                      height: "20px",
                      paddingLeft: "0.5%",
                      opacity: "0.8",
                    }}
                  />
                </div>
              </th>
              <td>{Math.round(Data.bathrooms)} kpl</td>
              <td>{Data.bathroom_renovated}</td>
              <td>{Defaults.Bathroomspan}</td>
              <td>{Data.bathroom_actualPlanned}</td>
              <td>{Math.round(Data.bathroom_rRPrice)}</td>
              <td>
                <CheckNaN
                  maintenance={Data.bathroom_maintenance}
                  apartment={Data.apartmentArea}
                />
              </td>
              <td>{Data.bathroom_maintenance}</td>
            </tr>
            <tr>
              <th className={classes.row}>
                Lämmitysputkisto
                <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                  <span
                    style={
                      trafficLight(
                        Number(Data.heating_actualPlanned) -
                          new Date(Date.now()).getFullYear(),
                        Data.heating_renovated +
                          Defaults.Heatingspan -
                          new Date(Date.now()).getFullYear()
                      ) === 0
                        ? {
                            width: "19px",
                            height: "19px",
                            backgroundColor: "red",
                            borderRadius: "50%",
                            border: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }
                        : trafficLight(
                            Number(Data.heating_actualPlanned) -
                              new Date(Date.now()).getFullYear(),
                            Data.heating_renovated +
                              Defaults.Heatingspan -
                              new Date(Date.now()).getFullYear()
                          ) === 1
                        ? {
                            width: "19px",
                            height: "19px",
                            backgroundColor: "yellow",
                            borderRadius: "50%",
                            border: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }
                        : {
                            width: "19px",
                            height: "19px",
                            backgroundColor: "green",
                            borderRadius: "50%",
                            border: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }
                    }
                  />
                  <img
                    id="heating-img"
                    src={question}
                    alt="prop"
                    onClick={() =>
                      navigate("/info", {
                        state: {
                          title: "Lämmitysputkisto",
                          text: "Vesikiertoisen keskuslämmitysjärjestelmän vesiputkisto ja lämmityspatterit vaihdetaan toisinaan linjasaneerauksen yhteydessä, yleensä kuitenkin omana projektinaan. Tyypillisesti näiden lämmitysputkien ja -pattereiden elinikä on pidempi kuin vesi- tai viemäriputkien elinikä, normaalisti jopa 80 v. Elinikää lyhentää, mikäli lämmitysputkistoon pääsee ilmaa, mikä jouduttaa korroosiota.",
                        },
                      })
                    }
                    style={{
                      height: "20px",
                      paddingLeft: "0.5%",
                      opacity: "0.8",
                    }}
                  />
                </div>
              </th>
              <td>{Math.round(Data.heating_area)} m²</td>
              <td>{Data.heating_renovated}</td>
              <td>{Defaults.Heatingspan}</td>
              <td>{Data.heating_actualPlanned}</td>
              <td>{Math.round(Data.heating_rRPrice)}</td>
              <td>
                <CheckNaN
                  maintenance={Data.heating_maintenance}
                  apartment={Data.apartmentArea}
                />
              </td>
              <td>{Data.heating_maintenance}</td>
            </tr>
            <tr>
              <th className={classes.row}>
                Sähkö- ja telejärjestelmä
                <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                  <span
                    style={
                      trafficLight(
                        Number(Data.elec_actualPlanned) -
                          new Date(Date.now()).getFullYear(),
                        Data.elec_renovated +
                          Defaults.Elecspan -
                          new Date(Date.now()).getFullYear()
                      ) === 0
                        ? {
                            width: "19px",
                            height: "19px",
                            backgroundColor: "red",
                            borderRadius: "50%",
                            border: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }
                        : trafficLight(
                            Number(Data.elec_actualPlanned) -
                              new Date(Date.now()).getFullYear(),
                            Data.elec_renovated +
                              Defaults.Elecspan -
                              new Date(Date.now()).getFullYear()
                          ) === 1
                        ? {
                            width: "19px",
                            height: "19px",
                            backgroundColor: "yellow",
                            borderRadius: "50%",
                            border: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }
                        : {
                            width: "19px",
                            height: "19px",
                            backgroundColor: "green",
                            borderRadius: "50%",
                            border: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }
                    }
                  />
                  <img
                    id="elec-img"
                    src={question}
                    alt="prop"
                    onClick={() =>
                      navigate("/info", {
                        state: {
                          title: "Sähkö- ja telejärjestelmä",
                          text: "Taloyhtiön Sähkö- ja telejärjestelmät sekä antenniverkko uusitaan usein linjasaneerauksen aikana.\n\ntuleva sähkön syöttökaapeli tulee sähkölaitokselta sähkön talojakamoon eli pääkeskukseen, josta se voidaan jakaa nousukeskuksiin, joita voi olla esimerkiksi yksi joka rapussa.\n\nNäistä nousukeskuksista niin sanottu sähkön nousujohto tulee asuntokohtaisiin jako-, eli mittarikeskuksiin, jotka ovat yleensä sisällä asunnoissa. Niistä sähköjohdot lähtevät eri huoneisiin omien sulakkeidensa kautta.\n\nTaloyhtiön sähkölaitteiden korjaus sähkölaitoksen rajapinnasta asuntojen mittarikeskukseen maksanee ehkä ¼ koko sähkölaitteiden uusimisesta. Asuntojen sisäiset johdotukset ja asunnon sähkökeskus lienevät yhteensä ¾ koko remontin kustannuksista.\n\nAsunnoissa oleva vanha sähköjärjestelmä voi olla yksivaiheinen, 2-johtiminen, eikä sisällä maadoitusjohdinta, mikä lisätään asunnon sisäisiin johdotuksiin uusissa asennuksissa. Tämä aiheuttaa myös vanhojen pistorasioiden uusimisen.\n\nTV/Radiokaapeloinnin, puhelin- ja mahdollisten datakaapelien tekniikka on vaihtunut ja uudistunut useasti vuosien saatossa. Nämä kaapelit on todennäköisesti vaihdettu uuteen järjestelmään  useammin kuin varsinaiset sähköjohdot, jotka voivat kestää jopa 80 vuotta.\n\nYhtiöjärjestyksessä voi olla mainittu, että asuntojen sisäiset sähkö- ja antennijohdotukset keskuksineen ovat osakkaan vastuulla. Lisäksi osakas on voinut lisätä asuntoon vuosien saatossa pistorasioita, mitkä ovat ns. taloyhtiön vastuulla olevan ja maksaman perustason ulkopuolella ja tällöin osakkaan vastuulla.",
                        },
                      })
                    }
                    style={{
                      height: "20px",
                      paddingLeft: "0.5%",
                      opacity: "0.8",
                    }}
                  />
                </div>
              </th>
              <td>{Math.round(Data.elec_area)} m²</td>
              <td>{Data.elec_renovated}</td>
              <td>{Defaults.Elecspan}</td>
              <td>{Data.elec_actualPlanned}</td>
              <td>{Math.round(Data.elec_rRPrice)}</td>
              <td>
                <CheckNaN
                  maintenance={Data.elec_maintenance}
                  apartment={Data.apartmentArea}
                />
              </td>
              <td>{Data.elec_maintenance}</td>
            </tr>
            <tr>
              <th className={classes.row}>
                Ilmanvaihto, hormit
                <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                  <span
                    style={
                      trafficLight(
                        Number(Data.vent_actualPlanned) -
                          new Date(Date.now()).getFullYear(),
                        Data.vent_renovated +
                          Defaults.Ventspan -
                          new Date(Date.now()).getFullYear()
                      ) === 0
                        ? {
                            width: "19px",
                            height: "19px",
                            backgroundColor: "red",
                            borderRadius: "50%",
                            border: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }
                        : trafficLight(
                            Number(Data.vent_actualPlanned) -
                              new Date(Date.now()).getFullYear(),
                            Data.vent_renovated +
                              Defaults.Ventspan -
                              new Date(Date.now()).getFullYear()
                          ) === 1
                        ? {
                            width: "19px",
                            height: "19px",
                            backgroundColor: "yellow",
                            borderRadius: "50%",
                            border: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }
                        : {
                            width: "19px",
                            height: "19px",
                            backgroundColor: "green",
                            borderRadius: "50%",
                            border: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }
                    }
                  />
                  <img
                    id="vent-img"
                    src={question}
                    alt="prop"
                    onClick={() =>
                      navigate("/info", {
                        state: {
                          title: "Ilmanvaihto, hormit",
                          text: "Vanhoissa asuinrakennuksissa on yleensä painovoimainen ilmanvaihto. Tällöin ulkoilma tulee seinissä tai ikkunoissa olevien tuloilma-aukkojen kautta ja poistuu hormeihin poistoilma-aukoista ilman lämpötilaerojen aiheuttamana. Poistoilma-aukkoja pitää olla säännösten mukaan keittiössä, märkätiloissa ja vaatehuoneissa.\n\nVanhojen rakennusten hormit on muurattu tiilestä ja uudemmissa on käytetty teräsputkia. Painovoimaisen ilmanvaihdon tiilimuuraukset kestävät pitkään, tyypillisesti jopa 80 v. Jos taloyhtiö joutuu tekemän hormiremontin, se sisältää yleensä hormijärjestelmien tutkimista, rapautuneiden ja umpeen romahtaneiden hormien uusintaa, joidenkin hormien sukittamista, putkittamista tai slammausta ja useista eri syistä umpeen menneiden tai vuotavien tiilihormien avaamista\n\nUudemmissa rakennuksissa on ilmanvaihtoa parannettu koneellisella poistolla eli huippuimurilla, joka on talon katolla. Kaikkein uusimmat talot on varustettu yleensä lisäksi lämmön talteenotolla ja ilman tuloilmaputkistolla eli ns. LTO kojeistolla. Näiden ilmanvaihtokojeiden elinikä on noin 20 v ja vaihtamisen kustannus on yleensä alle 10 000€/kpl",
                        },
                      })
                    }
                    style={{
                      height: "20px",
                      paddingLeft: "0.5%",
                      opacity: "0.8",
                    }}
                  />
                </div>
              </th>
              <td>{Math.round(Data.vent_area)} m²</td>
              <td>{Data.vent_renovated}</td>
              <td>{Defaults.Ventspan}</td>
              <td>{Data.vent_actualPlanned}</td>
              <td>{Math.round(Data.vent_rRPrice)}</td>
              <td>
                <CheckNaN
                  maintenance={Data.vent_maintenance}
                  apartment={Data.apartmentArea}
                />
              </td>
              <td>{Data.vent_maintenance}</td>
            </tr>
            <tr>
              <th className={classes.row}>
                Julkisivut
                <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                  <span
                    style={
                      trafficLight(
                        Number(Data.front_actualPlanned) -
                          new Date(Date.now()).getFullYear(),
                        Data.front_renovated +
                          Defaults.Frontspan -
                          new Date(Date.now()).getFullYear()
                      ) === 0
                        ? {
                            width: "19px",
                            height: "19px",
                            backgroundColor: "red",
                            borderRadius: "50%",
                            border: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }
                        : trafficLight(
                            Number(Data.front_actualPlanned) -
                              new Date(Date.now()).getFullYear(),
                            Data.front_renovated +
                              Defaults.Frontspan -
                              new Date(Date.now()).getFullYear()
                          ) === 1
                        ? {
                            width: "19px",
                            height: "19px",
                            backgroundColor: "yellow",
                            borderRadius: "50%",
                            border: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }
                        : {
                            width: "19px",
                            height: "19px",
                            backgroundColor: "green",
                            borderRadius: "50%",
                            border: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }
                    }
                  />
                  <img
                    id="front-img"
                    src={question}
                    alt="prop"
                    onClick={() =>
                      navigate("/info", {
                        state: {
                          title: "Julkisivut, ikkunat, parvekkeet",
                          text: "Julkisivut, ikkunat ja parvekkeet voidaan kaikki remontoida samalla kertaa.\n\nUsein kuitenkin auringolle ja säälle alttiimpana oleva julkisivu ja sen parvekkeet ja varsinkin ikkunat joudutaan uusimaan useammin kuin varjon puolella olevat sisäpihan rakenteet.\n\nJulkisivumateriaali voi olla puuta, tiiltä, betonia tai vanhoissa kerrostaloissa usein rappausta. Näiden elinikä vaihtelee sään, materiaalin ja kunnossapidon perusteella 20 ja 70 vuoden välillä. Julkisivun kuntoa voi arvioida sitä silmämääräisesti tarkastelemalla. Julkisivua ja ikkunoita kunnostettaessa koko kerrostalon julkisivu yleensä suojataan ns. hupulla. Hupun peittäessä talon, tai sen osan, asunnossa voi kyllä asua, mutta näköalat voivat olla aika yksitoikkoiset. Julkisivuremontti kestää vajaasta puolesta vuodesta jopa lähes vuoteen.",
                        },
                      })
                    }
                    style={{
                      height: "20px",
                      paddingLeft: "0.5%",
                      opacity: "0.8",
                    }}
                  />
                </div>
              </th>
              <td>{Math.round(Data.front_area)} m²</td>
              <td>{Data.front_renovated}</td>
              <td>{Defaults.Frontspan}</td>
              <td>{Data.front_actualPlanned}</td>
              <td>{Math.round(Data.front_rRPrice)}</td>
              <td>
                <CheckNaN
                  maintenance={Data.front_maintenance}
                  apartment={Data.apartmentArea}
                />
              </td>
              <td>{Data.front_maintenance}</td>
            </tr>
            <tr>
              <th className={classes.row}>
                Ikkunat ja parvekkeet
                <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                  <span
                    style={
                      trafficLight(
                        Number(Data.windows_and_balconies_actualPlanned) -
                          new Date(Date.now()).getFullYear(),
                        Data.windows_and_balconies_renovated +
                          Defaults.Windowsandbalconiesspan -
                          new Date(Date.now()).getFullYear()
                      ) === 0
                        ? {
                            width: "19px",
                            height: "19px",
                            backgroundColor: "red",
                            borderRadius: "50%",
                            border: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }
                        : trafficLight(
                            Number(Data.windows_and_balconies_actualPlanned) -
                              new Date(Date.now()).getFullYear(),
                            Data.windows_and_balconies_renovated +
                              Defaults.Windowsandbalconiesspan -
                              new Date(Date.now()).getFullYear()
                          ) === 1
                        ? {
                            width: "19px",
                            height: "19px",
                            backgroundColor: "yellow",
                            borderRadius: "50%",
                            border: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }
                        : {
                            width: "19px",
                            height: "19px",
                            backgroundColor: "green",
                            borderRadius: "50%",
                            border: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }
                    }
                  />
                  <img
                    id="front-img"
                    src={question}
                    alt="prop"
                    onClick={() =>
                      navigate("/info", {
                        state: {
                          title: "Ikkunat ja parvekkeet",
                          text: "Julkisivut, ikkunat ja parvekkeet voidaan kaikki remontoida samalla kertaa.\n\nUsein kuitenkin auringolle ja säälle alttiimpana oleva julkisivu ja sen parvekkeet ja varsinkin ikkunat joudutaan uusimaan useammin kuin varjon puolella olevat sisäpihan rakenteet.\n\nJulkisivumateriaali voi olla puuta, tiiltä, betonia tai vanhoissa kerrostaloissa usein rappausta. Näiden elinikä vaihtelee sään, materiaalin ja kunnossapidon perusteella 20 ja 70 vuoden välillä. Julkisivun kuntoa voi arvioida sitä silmämääräisesti tarkastelemalla. Julkisivua ja ikkunoita kunnostettaessa koko kerrostalon julkisivu yleensä suojataan ns. hupulla. Hupun peittäessä talon, tai sen osan, asunnossa voi kyllä asua, mutta näköalat voivat olla aika yksitoikkoiset. Julkisivuremontti kestää vajaasta puolesta vuodesta jopa lähes vuoteen.",
                        },
                      })
                    }
                    style={{
                      height: "20px",
                      paddingLeft: "0.5%",
                      opacity: "0.8",
                    }}
                  />
                </div>
              </th>
              <td>{/* {Math.round(Data.windows_and_balconies_area)} m² */}</td>
              <td>{Data.windows_and_balconies_renovated}</td>
              <td>{Defaults.Windowsandbalconiesspan}</td>
              <td>{Data.windows_and_balconies_actualPlanned}</td>
              <td>{Math.round(Data.windows_and_balconies_rRPrice)}</td>
              <td>
                <CheckNaN
                  maintenance={Data.windows_and_balconies_maintenance}
                  apartment={Data.apartmentArea}
                />
              </td>
              <td>{Data.windows_and_balconies_maintenance}</td>
            </tr>
            <tr>
              <th className={classes.row}>
                Salaojat ja sokkeli
                <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                  <span
                    style={
                      trafficLight(
                        Number(Data.yard_actualPlanned) -
                          new Date(Date.now()).getFullYear(),
                        Data.yard_renovated +
                          Defaults.Yardspan -
                          new Date(Date.now()).getFullYear()
                      ) === 0
                        ? {
                            width: "19px",
                            height: "19px",
                            backgroundColor: "red",
                            borderRadius: "50%",
                            border: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }
                        : trafficLight(
                            Number(Data.yard_actualPlanned) -
                              new Date(Date.now()).getFullYear(),
                            Data.yard_renovated +
                              Defaults.Yardspan -
                              new Date(Date.now()).getFullYear()
                          ) === 1
                        ? {
                            width: "19px",
                            height: "19px",
                            backgroundColor: "yellow",
                            borderRadius: "50%",
                            border: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }
                        : {
                            width: "19px",
                            height: "19px",
                            backgroundColor: "green",
                            borderRadius: "50%",
                            border: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }
                    }
                  />
                  <img
                    id="yard-img"
                    src={question}
                    alt="prop"
                    onClick={() =>
                      navigate("/info", {
                        state: {
                          title: "Salaojat, sokkeli, pihakansi",
                          text: "Maan alla olevat salaojat ja rakennuksen maata vasten oleva osa, eli sokkeli ja sokkelin vedeneristys kaipaavat korjauksia ja uusimista noin 35 vuoden välein.\n\nKallioiset, viettävät tontit vieri viereen asutussa keskustassa kärsivät toisinaan sokkelin ja kellarin tiivistysongelmista, mikä voi aiheuttaa vesi- ja kosteusvahinkoja kellarissa tai 1. kerroksessa.\n\nMonissa kerrostaloissa on sisäpihalla ja porttikäytävissä pihakansi, varsinkin jos taloyhtiössä on suuret kellaritilat tai maan alainen autotalli.",
                        },
                      })
                    }
                    style={{
                      height: "20px",
                      paddingLeft: "0.5%",
                      opacity: "0.8",
                    }}
                  />
                </div>
              </th>
              <td>{/* Math.round(Data.yard_area) */}</td>
              <td>{Data.yard_renovated}</td>
              <td>{Defaults.Yardspan}</td>
              <td>{Data.yard_actualPlanned}</td>
              <td>{Math.round(Data.yard_rRPrice)}</td>
              <td>
                <CheckNaN
                  maintenance={Data.yard_maintenance}
                  apartment={Data.apartmentArea}
                />
              </td>
              <td>{Data.yard_maintenance}</td>
            </tr>
            {Data.yard_cover_maintenanceDebtPerSqm !==
              "0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0" &&
            Data.yard_cover_maintenanceDebtPerSqm !== "" ? (
              <tr>
                <th className={classes.row}>
                  Pihakansi
                  <div
                    style={{ display: "flex", alignItems: "center", gap: 10 }}
                  >
                    <span
                      style={
                        trafficLight(
                          Number(Data.yard_cover_actualPlanned) -
                            new Date(Date.now()).getFullYear(),
                          Data.yard_cover_renovated +
                            Defaults.Yardcoverspan -
                            new Date(Date.now()).getFullYear()
                        ) === 0
                          ? {
                              width: "19px",
                              height: "19px",
                              backgroundColor: "red",
                              borderRadius: "50%",
                              border: "black",
                              borderStyle: "solid",
                              borderWidth: "1px",
                            }
                          : trafficLight(
                              Number(Data.yard_cover_actualPlanned) -
                                new Date(Date.now()).getFullYear(),
                              Data.yard_cover_renovated +
                                Defaults.Yardcoverspan -
                                new Date(Date.now()).getFullYear()
                            ) === 1
                          ? {
                              width: "19px",
                              height: "19px",
                              backgroundColor: "yellow",
                              borderRadius: "50%",
                              border: "black",
                              borderStyle: "solid",
                              borderWidth: "1px",
                            }
                          : {
                              width: "19px",
                              height: "19px",
                              backgroundColor: "green",
                              borderRadius: "50%",
                              border: "black",
                              borderStyle: "solid",
                              borderWidth: "1px",
                            }
                      }
                    />
                    <img
                      id="yardCase-img"
                      src={question}
                      alt="prop"
                      onClick={() =>
                        navigate("/info", {
                          state: {
                            title: "Pihakansi",
                            text: "Maan alla olevat salaojat ja rakennuksen maata vasten oleva osa, eli sokkeli ja sokkelin vedeneristys kaipaavat korjauksia ja uusimista noin 35 vuoden välein.\n\nKallioiset, viettävät tontit vieri viereen asutussa keskustassa kärsivät toisinaan sokkelin ja kellarin tiivistysongelmista, mikä voi aiheuttaa vesi- ja kosteusvahinkoja kellarissa tai 1. kerroksessa.\n\nMonissa kerrostaloissa on sisäpihalla ja porttikäytävissä pihakansi, varsinkin jos taloyhtiössä on suuret kellaritilat tai maan alainen autotalli.",
                          },
                        })
                      }
                      style={{
                        height: "20px",
                        paddingLeft: "0.5%",
                        opacity: "0.8",
                      }}
                    />
                  </div>
                </th>
                <td>{/* {Math.round(Data.yard_cover_area)} */}</td>
                <td>{Data.yard_cover_renovated}</td>
                <td>{Defaults.Yardspan}</td>
                <td>{Data.yard_cover_actualPlanned}</td>
                <td>{Math.round(Data.yard_cover_rRPrice)}</td>
                <td>
                  <CheckNaN
                    maintenance={Data.yard_cover_maintenance}
                    apartment={Data.apartmentArea}
                  />
                </td>
                <td>{Data.yard_cover_maintenance}</td>
              </tr>
            ) : (
              <></>
            )}
            <tr>
              <th className={classes.row}>
                Katto
                <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                  <span
                    style={
                      trafficLight(
                        Number(Data.roof_actualPlanned) -
                          new Date(Date.now()).getFullYear(),
                        Data.roof_renovated +
                          Defaults.Roofspan -
                          new Date(Date.now()).getFullYear()
                      ) === 0
                        ? {
                            width: "19px",
                            height: "19px",
                            backgroundColor: "red",
                            borderRadius: "50%",
                            border: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }
                        : trafficLight(
                            Number(Data.roof_actualPlanned) -
                              new Date(Date.now()).getFullYear(),
                            Data.roof_renovated +
                              Defaults.Roofspan -
                              new Date(Date.now()).getFullYear()
                          ) === 1
                        ? {
                            width: "19px",
                            height: "19px",
                            backgroundColor: "yellow",
                            borderRadius: "50%",
                            border: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }
                        : {
                            width: "19px",
                            height: "19px",
                            backgroundColor: "green",
                            borderRadius: "50%",
                            border: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }
                    }
                  />
                  <img
                    id="roof-img"
                    src={question}
                    alt="prop"
                    onClick={() =>
                      navigate("/info", {
                        state: {
                          title: "Vesikatto",
                          text: "Vesikaton, eli katon korjauksessa vaihdetaan vesikate, joka voi olla tiiltä, bitumihuopaa tai peltiä. Samalla vaihdetaan vesikaton alapuolella olevat aluskatteet ja mahdolliset katon huonokuntoiset tukirakenteet ja ns. kattoturvatuotteet.\n\nKaton uusiminen maksaa noin 330 € / kattoneliö. Keskustassa korkeat talot joutuvat yleensä maksamaan enemmän mm. telineiden ja nosturien kadunvaraus- ja pysäköintimaksuista ja korkeammista telineistä johtuen kuin matalammat talot keskustan ulkopuolella.\n\nTyypillinen viisikerroksinen kerrostalo, jossa on 3000-5000 kerrosneliötä, maksaa kattoremontista 250 000 – 450 000 euroa. Asuntoneliötä kohti kattoremontti maksaa tällaisessa talossa noin 70 €/m2.\n\nParas aika rakentaa ullakolle kattoasuntoja on tietenkin katon uusimisen yhteydessä.",
                        },
                      })
                    }
                    style={{
                      height: "20px",
                      paddingLeft: "0.5%",
                      opacity: "0.8",
                    }}
                  />
                </div>
              </th>
              <td>
                {Math.round(
                  ((Data.residentialArea + Data.commercialArea) / Data.floors) *
                    1.2
                )}{" "}
                m²
              </td>
              <td>{Data.roof_renovated}</td>
              <td>{Defaults.Roofspan}</td>
              <td>{Data.roof_actualPlanned}</td>
              <td>{Math.round(Data.roof_rRPrice)}</td>
              <td>
                <CheckNaN
                  maintenance={Data.roof_maintenance}
                  apartment={Data.apartmentArea}
                />
              </td>
              <td>{Data.roof_maintenance}</td>
            </tr>
            <tr>
              <th className={classes.row}>
                Hissi
                <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                  {Data.elevators !== 0 && (
                    <span
                      style={
                        trafficLight(
                          Number(Data.elevators_actualPlanned) -
                            new Date(Date.now()).getFullYear(),
                          Data.elevators_renovated +
                            Defaults.Elevatorspan -
                            new Date(Date.now()).getFullYear()
                        ) === 0
                          ? {
                              width: "19px",
                              height: "19px",
                              backgroundColor: "red",
                              borderRadius: "50%",
                              border: "black",
                              borderStyle: "solid",
                              borderWidth: "1px",
                            }
                          : trafficLight(
                              Number(Data.elevators_actualPlanned) -
                                new Date(Date.now()).getFullYear(),
                              Data.elevators_renovated +
                                Defaults.Elevatorspan -
                                new Date(Date.now()).getFullYear()
                            ) === 1
                          ? {
                              width: "19px",
                              height: "19px",
                              backgroundColor: "yellow",
                              borderRadius: "50%",
                              border: "black",
                              borderStyle: "solid",
                              borderWidth: "1px",
                            }
                          : {
                              width: "19px",
                              height: "19px",
                              backgroundColor: "green",
                              borderRadius: "50%",
                              border: "black",
                              borderStyle: "solid",
                              borderWidth: "1px",
                            }
                      }
                    />
                  )}

                  <img
                    id="elevators-img"
                    src={question}
                    alt="prop"
                    onClick={() =>
                      navigate("/info", {
                        state: {
                          title: "Hissi",
                          text: "Hissi vaatii viranomaistarkastuksia ja huoltoa lähes vuosittain. Perusteellisempi uusinta joudutaan tekemään hissin sähkö- ja turvatekniikalle, ovimekaniikalle, köysille ja jopa hissikorille suunnilleen 30 vuoden välein.",
                        },
                      })
                    }
                    style={{
                      height: "20px",
                      paddingLeft: "0.5%",
                      opacity: "0.8",
                    }}
                  />
                </div>
              </th>
              <td>{Math.round(Data.elevators)} kpl</td>
              <td>{Data.elevators_renovated}</td>
              <td>{Defaults.Elevatorspan}</td>
              <td>{Data.elevators_actualPlanned}</td>
              <td>{Math.round(Data.elevators_rRPrice)}</td>
              <td>
                <CheckNaN
                  maintenance={Data.elevators_maintenance}
                  apartment={Data.apartmentArea}
                />
              </td>
              <td>{Data.elevators_maintenance}</td>
            </tr>
            <tr>
              <th style={{ border: "none" }}>Yhteensä</th>
              <td style={{ border: "none" }}></td>
              <td style={{ border: "none" }}></td>
              <td style={{ border: "none" }}></td>
              <td style={{ border: "none" }}></td>
              <td style={{ fontWeight: "bold", border: "none" }}>
                {Math.round(
                  Data.sewer_rRPrice +
                    Data.plumbing_rRPrice +
                    Data.bathroom_rRPrice +
                    Data.heating_rRPrice +
                    Data.elec_rRPrice +
                    Data.vent_rRPrice +
                    Data.front_rRPrice +
                    Data.windows_and_balconies_rRPrice +
                    Data.yard_rRPrice +
                    Data.yard_cover_rRPrice +
                    Data.roof_rRPrice +
                    Data.elevators_rRPrice
                )}
              </td>

              <td style={{ fontWeight: "bold", border: "none" }}>
                <CheckTotal
                  total={Data.total_maintenance}
                  apartment={Data.apartmentArea}
                />
              </td>
              <td style={{ fontWeight: "bold", border: "none" }}>
                {Data.total_maintenance}
              </td>
            </tr>
          </tbody>
        </table>
        <div
          style={{
            display: "flex",
            width: "100%",
            gap: "20px",
            marginTop: "10px",
            justifyContent: "center",
          }}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <span
              style={{
                width: "19px",
                height: "19px",
                backgroundColor: "red",
                borderRadius: "50%",
                border: "black",
                borderStyle: "solid",
                borderWidth: "1px",
              }}
            />
            Teknistä käyttöikää 5 vuotta tai alle
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <span
              style={{
                width: "19px",
                height: "19px",
                backgroundColor: "yellow",
                borderRadius: "50%",
                border: "black",
                borderStyle: "solid",
                borderWidth: "1px",
              }}
            />
            Teknistä käyttöikää 6-15 vuotta
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <span
              style={{
                width: "19px",
                height: "19px",
                backgroundColor: "green",
                borderRadius: "50%",
                border: "black",
                borderStyle: "solid",
                borderWidth: "1px",
              }}
            />
            Teknistä käyttöikää yli 15 vuotta
          </div>
        </div>
        <h3 style={{ paddingTop: "2.5%" }}>
          Taloyhtiön korjausvelan määrä €/m<sup>2</sup>, sekä tulevien
          remonttien kustannukset tälle asunnolle /€
        </h3>
        <DebtChart />
        {/* <h3 style={{ paddingTop: "2.5%" }}>
          {Data.apartmentArea}m<sup>2</sup> asunnon rahoitusvastikkeen lisäys
          (€/kk)
        </h3>
        <p>
          {Data.loanperiod} vuoden laina-ajalla ja {Data.rate} % korolla
        </p>
        <CompensationChart /> */}
        <h3 style={{ paddingTop: "2.5%" }}>
          {Data.apartmentArea}m<sup>2</sup> asunnon rahoitusvastikkeen arvioitu
          lisäys (korkomaksut ja lyhennys eriteltynä €/kk)
        </h3>
        <p>
          {Data.loanperiod} vuoden laina-ajalla ja {Data.rate} % korolla
        </p>
        <SeparatedCompensationChart />
      </div>
      <div
        style={{
          position: "absolute",
          top: "-9999px",
          left: "-9999px",
        }}
      >
        <A4Raport data={Data} defaults={Defaults} />
        <A4Raport2 data={Data} defaults={Defaults} />
        <A4RaportOnePage data={Data} defaults={Defaults} />
      </div>
    </div>
  );
}

export default Raport;
